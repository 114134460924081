// Here you can add other styles
.react-datepicker-popper {
  z-index: 9999;
}

.fix {
  z-index: 9999;
}

.purchaseImportAlert {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;

  button {
    width: 15%;
    background-color: rgb(47, 133, 247);
    border-color: rgb(47, 133, 247);
    border-radius: 5px;
    color: rgb(255, 255, 255);
  }
}

.purchaseImportAlertTitle {
  h2 {color: red;}
}